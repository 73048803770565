import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Customer, GiftCard, Card } from '@/models/index'

@Model()
export default class Gift extends ApplicationRecord {
  static jsonapiType = 'gifts'

  @Attr() id!: string
  @Attr() message!: string
  @Attr() createdAt!: string
  @Attr() updatedAt!: string
  @Attr() cardDistributions!: any
  @Attr() giftCardId!: string
  @Attr() opened!: boolean

  @BelongsTo() sender!: any
  @BelongsTo() recipient!: Customer
  @BelongsTo() giftCard!: GiftCard

  @HasMany() cards!: Card[]

  public getColorByName() {
    let name = ''
    let hash = 0

    if (this.recipient?.firstname && this.recipient?.lastname) name = this.recipient.firstname + this.recipient.lastname

    if (name.length === 0) return hash
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }
}
