import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Customer, DotationOperation, CustomerOrder, Caretaker, MultiChoiceGiftCard } from '@/models/index'

@Model()
export default class MultiChoiceCard extends ApplicationRecord {
  static jsonapiType = 'multi_choice_cards'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() amount!: number
  @Attr() remainingAmount!: number
  @Attr() expireAt!: string
  @Attr() usedAt!: string
  @Attr() createdAt!: string
  @Attr() activateAt!: string
  @Attr() multiChoiceCardDistributions!: any[]

  @BelongsTo() source!: Caretaker | CustomerOrder
  @BelongsTo() customer!: Customer
  @BelongsTo() dotationOperation!: DotationOperation
  @BelongsTo() customerOrder!: CustomerOrder
  @BelongsTo() multiChoiceGiftCard!: MultiChoiceGiftCard

  public getColorByName() {
    let name = ''
    let hash = 0

    if (this.customer?.firstname && this.customer?.lastname) name = this.customer.firstname + this.customer.lastname

    if (name.length === 0) return hash
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }
}
