import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Budget } from '@/models/index'

@Model()
export default class BudgetMove extends ApplicationRecord {
  static jsonapiType = 'budget_moves'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() creditedAt!: string
  @Attr() sourceType!: string
  @Attr() createdAt!: string

  @BelongsTo() budget!: Budget
  @BelongsTo() source!: any

  get sourceName() {
    let name = ''

    if (this.sourceType === 'BankTransfer')
      name = this.source.reference
    else
      name = this.source.customer ? `${this.source.customer.firstname} ${this.source.customer.lastname}` : 'Carte Vierge'

    return name
  }
}
